<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    :close-on-click="false"
    :nudge-width="options.width"
    :position-x="x"
    :position-y="y"
    absolute
    offset-y
    origin="center center"
    transition="scale-transition"
  >
    <!-- <v-card>
      <v-system-bar
        color="primary"
        dark
      >
        <v-spacer></v-spacer>
        <v-icon
          @click.stop="agree"
        >mdi-close</v-icon>
      </v-system-bar>
      <v-card-text>
        <span class="subheading">차수 선택</span>
        <v-chip-group
          v-model="selection"
          mandatory
          column
          active-class="primary--text"
        >
          <v-chip v-for="tag in selects" :key="tag">
            {{ tag }}
          </v-chip>
        </v-chip-group>
      </v-card-text>
    </v-card> -->
    <v-card>
      <v-toolbar flat color="primary" dark>
        <v-btn icon @click.stop="agree">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>결재 히스토리</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <!-- <h2 class="title mb-2">차수 선택</h2> -->
        <v-chip-group
          v-model="selection"
          mandatory
          column
          active-class="primary--text"
        >
          <v-chip v-for="tag in selects" :key="tag"
            filter
            outlined
            @click.stop="selectTag(tag)"
          >
            {{ tag }}차
          </v-chip>
        </v-chip-group>

        <!-- <v-divider></v-divider> -->

        <v-card
          elevation="0"
          tile
          outlined
          class="overflow-y-auto pa-2"
          max-height="450"
          min-height="300"
        >
          <template v-for="(approver, i) in approvers">
            <v-row
              :key="i"
              no-gutters
            >
              <v-col cols="12" xs="12" sm="12">
                <v-card elevation="0">
                  <v-avatar tile size="20" color="blue">
                    <span class="white--text text-body-2">{{ i }}</span>
                  </v-avatar>
                  <v-list dense class="ma-0 pa-0">
                    <v-list-item
                      v-for="item in approver.items"
                      :key="item.email"
                      class="ma-0 py-0"
                    >
                      <span class="text-body-2">
                        <span :class="item.result === 4 ? 'grey--text text--lighten-1 text-decoration-line-through' : ''">{{ item.name }}</span>
                        <span class="text-caption grey--text text--darken-1">
                          {{ item.rank }}
                        </span>
                        <!-- !! 기안자의 처리상태 -->
                        <v-chip
                          v-show="item.tern === 1"
                          x-small label class="mx-1 px-2 text-caption"
                          color="primary"
                        >
                          상신
                        </v-chip>
                        <!-- !! 기안자 이외의 현재 처리상태 -->
                        <v-chip
                          v-show="item.tern !== 1"
                          x-small label class="mx-1 px-2 text-caption"
                          :color="resultColor[item.result]"
                        >
                          {{ resultTitle[item.result] }}
                        </v-chip>
                        <span v-show="item.result !== 0" class="mx-0 text-caption">
                          {{ strDateFormat2($moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss')) }}
                        </span>
                        <v-tooltip bottom color="grey darken-3">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-show="item.txt1"
                              v-on="on"
                              small text
                            >
                              <v-icon small>mdi-history</v-icon>
                            </v-btn>
                          </template>
                          <div id="vue2editorViewer" class="text-body-2 pa-1" style="max-width: 520px;" v-html="item.txt1"></div>
                          <!-- <span>히스토리</span> -->
                        </v-tooltip>
                      </span>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-card>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
// @: filters
import strDateFormat2 from '@/filters/strDateFormat2'

export default {
  data: () => ({
    resolve: null, // 이 방법이 너무 참신하다!
    reject: null,
    options: {
      color: 'primary',
      width: 600
    },
    showMenu: false,
    x: 0,
    y: 0,
    // 구분:
    pId: 0,
    depth: 0,
    selects: [],
    selection: 0,
    approvers: [],
    // 구분: 결재라인 결과에 따른 색상
    resultColor: {
      0: 'grey',
      1: 'success',
      2: 'warning',
      3: 'error',
      4: 'error'
    },
    // 구분: 결재라인 결과에 따른 타이틀
    resultTitle: {
      0: '대기',
      1: '승인',
      2: '반려',
      3: '불허',
      4: '오류'
    }
  }),

  computed: {
  },

  watch: {
  },

  methods: {
    strDateFormat2,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // 초기화
    async init () {
      try {
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 메뉴 팝업을 띄우는 함수
    async show (evt) {
      try {
        if (!this.showMenu) { // !! 안전장치 - 이미 열려있으면 다시 열리지 않게 한다.
          evt.preventDefault()
          this.showMenu = false
          this.x = evt.clientX
          this.y = evt.clientY

          // 초기화
          this.pId = 0
          this.depth = 0
          this.selection = 0
          this.selects = []
          this.approvers = []

          this.$nextTick(() => {
            this.showMenu = true
          })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // data setting
    async setData (item) {
      try {
        if (!item) throw new Error(`잘못된 인자 형식입니다.`)

        this.pId = item.pId
        this.depth = item.depth

        for (let i = this.depth; i > 0; i--) {
          this.selects.push(i)
        }
        this.selection = this.depth

        // 해당 차수의 결재자 리스트를 가져온다.
        await this.getApproversByPidDepth(this.pId, this.depth)

        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분:
    async getApproversByPidDepth (pId, depth) {
      try {
        if (!pId || !depth) throw new Error(`잘못된 인자입니다!`)

        const { data } = await this.$axios.get(`lawork/eapprove/getApproversByPidDepth/${pId}/${depth}`)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        this.approvers = data.approvers
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 태그 클릭 이벤트 핸들러
    async selectTag (tag) {
      try {
        // tag 값은 차수번호임!
        await this.getApproversByPidDepth(this.pId, tag)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 닫기 버튼 이벤트 핸들러
    async agree () {
      try {
        this.resolve(true)
        this.showMenu = false
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style>
/*
  !!참고: 모든 테이블에 공통적용되는 코드지만 각 파일에 있어야 한다.
  새로고침하면 적용이 안되고 적용된 페이지를 들러야 한다.
  v-data-talbe td 의 왼쪽,오른쪽 패딩 제거. 단 style 태그의 scoped 속성을 지워야 적용됨
*/
/* .v-data-table td{ 2020.6.15 변경됨*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 0;
  padding-right: 0;
}
.v-overflow-btn .v-select__selection--comma:first-child {
  margin-left: 5px;
  margin-right: 0px;
}
.v-overflow-btn .v-input__append-inner {
  width: 30px;
}
/* 중요: 모바일에서 테이블의 기본 값은 justify-content: space between 이다. 이걸 start 로 변경한다! */
.v-data-table__mobile-row {
  justify-content: start;
}
/*
  참고: 모든 vue2editor 뷰어에 공통적용
*/
#vue2editorViewer p {
  margin-bottom: 0px !important;
}
#vue2editorViewer2 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
#vue2editorViewer3 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
</style>
